import React, { useContext } from 'react';
import './Sidebar.scss';
import { Link } from 'react-router-dom'
import { Context } from '../../Context/Context';

const Sidebar = () => {
    const { setNavbar } = useContext(Context)
    function logout() {
        window.localStorage.clear()
    }
    const closeSidebar = () => {
        setNavbar(false);
    };
    return (
        <aside className="sidebar contai">
            <ul className="sidebar-list container" onChange={setNavbar(true)}>
                <Link className='exit-btn'><li className="sidebar-item"><i onClick={() => setNavbar(false)} className="fa-solid fa-xmark"></i> </li></Link>
                <Link className='dashboard'><li className="sidebar-item"><i className="fa-solid fa-gear"></i> Dashboard</li></Link>

                <Link to='/'><li onClick={closeSidebar} className="sidebar-item"><i className="fa-solid fa-house"></i> Bosh menyu</li></Link>
               
                <Link to='/news'><li onClick={closeSidebar} className="sidebar-item"><i className="fa-solid fa-image"></i> Yangiliklar</li></Link>
                <Link to='/info'><li onClick={closeSidebar} className="sidebar-item"><i className="fa-solid fa-share"></i> Davlatlar</li></Link>
                <Link to='/application'><li onClick={closeSidebar} className="sidebar-item"><i className="fa-solid fa-upload"></i>  Dasturlar</li></Link>
                <Link to='/gallery'><li onClick={closeSidebar} className="sidebar-item"><i className="fa-solid fa-image"></i> Galareya</li></Link>
                <Link to='/partner'><li onClick={closeSidebar} className="sidebar-item"><i className="fa-solid fa-image"></i> Hamkorlar</li></Link>
                <Link to='/contact'><li onClick={closeSidebar} className="sidebar-item"><i className="fa-solid fa-book"></i> Arizalar</li></Link>

                <Link to='/password'><li onClick={closeSidebar} className="sidebar-item"><i class="fa-solid fa-lock"></i> Parolni taxrirlash</li></Link>
                <Link onClick={logout}><li className="sidebar-item"><i className="fa-solid fa-right-to-bracket"></i> Chiqish</li></Link>
            </ul>
        </aside>
    );
};

export default Sidebar;
