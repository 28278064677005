import React, { useContext, useState, useEffect } from 'react';
import './Main.scss';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Navbar from '../../Components/Navbar/Navbar';
import { Context } from '../../Context/Context';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';

function Main() {
  const { navbar, setNavbar } = useContext(Context);
  const [statusData, setStatusData] = useState([]);

  useEffect(() => {
    // Fetch status data from the API
    axios.get('https://api.interstudent.uz/api/v1/status')
      .then(response => {
        setStatusData(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching status data:', error);
      });
  }, []);

  const handleStatusUpdate = (statusId, newValue, newTitle) => {
    // Update status data for the given statusId
    axios.put(`https://api.interstudent.uz/api/v1/status/update/${statusId}`, { value: newValue, title: newTitle })
      .then(response => {
        // Handle success
        toast.success('Yangilandi');
        // You may want to update the statusData state to reflect the changes
      })
      .catch(error => {
        // Handle error
        console.error('Error updating status:', error);
        toast.error('Yangilashda xatolik. Keyinroq urinib ko\'ring!');
      });
  };

  return (
    <>
     <ToastContainer />
      {navbar ? <Sidebar /> : null}
      <Navbar />
      <div className="main">
        <h2>Statistika</h2>
        <ul className="status">
          {statusData.map(status => (
            <li key={status._id}>
              <input
                type="text"
                value={status.title}
                onChange={e => setStatusData(prevState => prevState.map(item => item._id === status._id ? { ...item, title: e.target.value } : item))}
              />
              <input
                type="text"
                value={status.value}
                onChange={e => setStatusData(prevState => prevState.map(item => item._id === status._id ? { ...item, value: e.target.value } : item))}
              />
              <button onClick={() => handleStatusUpdate(status._id, status.value, status.title)}>Taxrirlash</button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default Main;
