import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Navbar from '../../Components/Navbar/Navbar';
import { Context } from '../../Context/Context';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import ExitIcon from '../../images/exit.png';

function Info() {
  const { navbar, setNavbar } = useContext(Context);
  const [video, setVideo] = useState(null);
  const [title_uz, setTitleUz] = useState('');
  const [description_uz, setDescriptionUz] = useState('');
  const [title_en, setTitleEn] = useState('');
  const [description_en, setDescriptionEn] = useState('');
  const [title_ru, setTitleRu] = useState('');
  const [description_ru, setDescriptionRu] = useState('');
  const [editingItemId, setEditingItemId] = useState(null);
  const [modal, setModal] = useState(false);
  const [images, setImages] = useState([])
  useEffect(() => {
    fetchImages();
  }, []);
  let navigate = useNavigate()

  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Adding 1 to match the typical month numbering
  const day = currentDate.getDate();

  const fetchImages = async () => {
    try {
      const response = await axios.get('https://api.interstudent.uz/api/v1/info');
      setImages(response?.data?.data);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };




  const handleEdit = (infoItem) => {
    setEditingItemId(infoItem._id);
    setTitleUz(infoItem.title_uz);
    setDescriptionUz(infoItem.description_uz);
    setTitleEn(infoItem.title_en);
    setDescriptionEn(infoItem.description_en);
    setTitleRu(infoItem.title_ru);
    setDescriptionRu(infoItem.description_ru);
    setVideo(infoItem.video)
    setModal(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault()

    try {
      await axios.put(`https://api.interstudent.uz/api/v1/info/update/${editingItemId}`, {
        title_uz,
        description_uz,
        title_en,
        description_en,
        title_ru,
        description_ru,
        video,
      });
      toast.success('Man\'lumot yangilandi');
      fetchImages();
      setModal(false);
    } catch (error) {
      console.error('Error updating news:', error);
      toast.error('Failed to update news');
    }
  };


  return (
    <>
      <ToastContainer />
      {navbar ? <Sidebar /> : null}
      <Navbar />
      <div className='main'>
       
        <div className="news-container">
          {images &&
            images.map((infoItem) => (
              <div key={infoItem._id} className="news-card">
                {infoItem?.video && <iframe src={`${infoItem.video}`} ></iframe>}
                <div className="news-actions">
                  <button className='edit-btn' onClick={() => handleEdit(infoItem)}>Taxrirlash</button>
                  <p>{`${infoItem.type}-${infoItem.date}`}</p>
                </div>
              </div>   
            ))}
        </div>
      </div>
      {modal &&
        <div className="modal">
          <div className="modal-list">
            <div className="modal-title">
              <h4>{'Tahrirlash'}</h4>
              <img src={ExitIcon} alt="Exit" onClick={() => {
                setModal(false)
                navigate(0)
              }} />
            </div>
            <form onSubmit={handleUpdate } className='main-form product-form'>
              <label>
                <input type="text" name="title_uz" value={title_uz} onChange={(e) => setTitleUz(e.target.value)} placeholder="Title (Uzbek)" required />
              </label>
              <label>
                <textarea type="text" name="description_uz" value={description_uz} onChange={(e) => setDescriptionUz(e.target.value)} placeholder="Description (Uzbek)" required />
              </label>
              <label>
                <input type="text" name="title_en" value={title_en} onChange={(e) => setTitleEn(e.target.value)} placeholder="Title (English)" required />
              </label>
              <label>
                <textarea type="text" name="description_en" value={description_en} onChange={(e) => setDescriptionEn(e.target.value)} placeholder="Description (English)" required />
              </label>
              <label>
                <input type="text" name="title_ru" value={title_ru} onChange={(e) => setTitleRu(e.target.value)} placeholder="Title (Russian)" required />
              </label>
              <label>
                <textarea type="text" name="description_ru" value={description_ru} onChange={(e) => setDescriptionRu(e.target.value)} placeholder="Description (Russian)" required />
              </label>
              <label>
              <input type="text" name="video" value={video} onChange={(e) => setVideo(e.target.value)} placeholder="You tube (SRC)" required />
              </label>
              <button type="submit">{'Yangilash' }</button>
            </form>
          </div>
        </div>
      }
    </>
  );
}

export default Info;
