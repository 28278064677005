import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Navbar from '../../Components/Navbar/Navbar';
import { Context } from '../../Context/Context';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import './Contact.scss'

function Contact() {
  const { navbar, setNavbar } = useContext(Context);
  const [messages, setMessages] = useState(null)

  const getMessages = () => {
    axios.get('https://api.interstudent.uz/api/v1/contact')
      .then(response => {
        setMessages(response.data.data);
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
      });
  }

  useEffect(() => {
    getMessages()
  }, [])

  const handleDelete = (id) => {
    const url = `https://api.interstudent.uz/api/v1/contact/delete/${id}`
    axios.delete(url)
      .then((data) => {
        toast.success('Muvaffaqiyatli o\'chirildi')
        getMessages()
      })
      .catch((err) => {
        console.log(err)
      })
    getMessages()
  }

  return (
    <>
      <ToastContainer />
      {navbar ? <Sidebar /> : null}
      <Navbar />

      <div className="applications-to-admin">
        {messages ? messages.map((message, idx) => {
          return (
            <div className={`application ${idx == messages.length-1 ? "margin-bottom-20" : "margin-bottom-20"}`} key={message._id}>
              <p className="application-author">Ismi: {message.firstname}</p>
              <p className="application-number">Raqami: {message.phone}</p>
              <p className="application-message">Xabari: {message.message}</p>
              <p className="application-date">Yuborilgan sana: {message.date}</p>
              <div className="application-buttons">
                <button className="btn btn-danger" onClick={() => handleDelete(message._id)}>O'chirish</button>
              </div>
            </div>
          )
        }) : "Loading ..."}
        {messages ? messages.length == 0 ? "Xozircha xabarlar yo'q" : "" : ""}
      </div>
    </>
  )
}

export default Contact
