import './App.css';
import { Routes, Route } from 'react-router-dom'
import Login from './Pages/Login/Login'
import LoginEdit from './Pages/LoginEdit/LoginEdit'
import Error from './Pages/Error/Error'
import News from './Pages/News/News'
import { Provider } from './Context/Context';
import { useEffect } from 'react';
import {useLocation,useNavigate} from 'react-router-dom'
import Main from './Pages/Main/Main';
import Contact from './Pages/Contact/Contact'
import Info from './Pages/Info/Info';
import Gallery from './Pages/Gallery/Gallary'
import Application from './Pages/Application/Application';
import Partner from './Pages/Partner/Partner';
function App() {
  const token = window.localStorage.getItem('OpenDashboard')
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (!token) {
      if (location.pathname === '/login') {
        navigate(location.pathname);
      } else {
        navigate('/login'); // Token yo'q va login yoki registerdan boshqasiga o'tishi kerak
      }
    }
  }, [location.pathname, token]);
  return (
    
    <Provider>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/partner' element={<Partner />} />
        <Route path='/application' element={<Application />} />
        <Route path='/login' element={<Login />} />
        <Route path='/news' element={<News />}/>
        <Route path='/password' element={<LoginEdit />} />
        <Route path='/info' element={<Info />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/*' element={<Error />} />
      </Routes>
    </Provider>
  );
}

export default App;
