import React, { useContext, useState } from 'react';
import './LoginEdit.scss';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Navbar from '../../Components/Navbar/Navbar';
import axios from 'axios';
import { Context } from '../../Context/Context';
import { toast, ToastContainer } from 'react-toastify';

function LoginEdit() {
  const { navbar, setNavbar } = useContext(Context);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const handlePasswordChange = () => {
    if (!currentPassword || !newPassword || !confirmNewPassword) {
      toast.error('Пожалуйста, заполните все поля.');
      return;
    }

    if (newPassword !== confirmNewPassword) {
      toast.error(' Ошибка подтверждения нового пароля.');
      return;
    }

    axios
  .get('https://api.interstudent.uz/api/v1/admin/auth')
  .then((response) => {
    if (response.status === 200) {
      const apiData = response.data?.data[0];
      if (apiData) {
        const apiPassword = apiData.password;
        if (currentPassword === apiPassword) {
          // Текущий пароль совпадает с паролем из API
          axios
            .put(`https://api.interstudent.uz/api/v1/admin/auth/update/${apiData._id}`, {
              password: newPassword,
            })
            .then((response) => {
              if (response.status === 200) {
                toast.success('Пароль успешно изменен.');
                setCurrentPassword('');
                setNewPassword('');
                setConfirmNewPassword('');
              }
            })
            .catch((error) => {
              toast.error('Произошла ошибка при смене пароля.');
              console.error('Xatolik:', error);
            });
        } else {
          toast.error('Текущий пароль неверен.');
        }
      } else {
        toast.error('Ошибка получения данных из API.');
      }
    }
  })
  .catch((error) => {
    toast.error('Произошла ошибка при подключении к серверу.');
    console.error('Xatolik:', error);
  });

  };

  return (
    <>
      <ToastContainer />
      {navbar ? <Sidebar /> : null}
      <Navbar />
      <div className='Forgot'>
      <h3 className="title">Parolni taxrirlash</h3>
      <form className="form">
        <input
          type="password"
          placeholder="joriy parol"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="yangi parol"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="yangi parolni takrorlang"
          value={confirmNewPassword}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
        />
        <button type="button" onClick={handlePasswordChange}>
        Yangilash
        </button>
      </form>
      </div>
    </>
  );
}

export default LoginEdit;
