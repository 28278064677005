import React, { useContext, useEffect, useState } from 'react';
import './Partner.scss';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Navbar from '../../Components/Navbar/Navbar';
import { Context } from '../../Context/Context';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import ExitIcon from '../../images/exit.png';

function Partner() {
  const { navbar, setNavbar } = useContext(Context);
  const [selectedFile, setSelectedFile] = useState(null);
  const [images, setImages] = useState(null);
  const [modal, setModal] = useState(false);
  const [base64File, setBase64File] = useState(null); // Faylni base64 formatiga o'zgarish uchun o'zgaruvchi


  
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  
  const handleUpload = async () => {
    if (!selectedFile) {
      toast.error('Iltimos rasm tanlang!');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', selectedFile);

      // Make a POST request to upload the image
      await axios.post('https://api.interstudent.uz/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const response = await axios.post('https://api.interstudent.uz/api/v1/partner/create', { image: selectedFile.name }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data) {
        toast.success('Hamkor joylandi');
      } else {
        toast.error('Xatolik yuz berdi!');
      }


      // Display success message
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Error uploading image');
    }
  };

  useEffect(() => {
    // Fetch the list of images when the component mounts
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get('https://api.interstudent.uz/api/v1/partner');
      setImages(response?.data?.data);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleDelete = async (image) => {
    try {
      // Make a DELETE request to delete the image from the server
      await axios.delete(`https://api.interstudent.uz/api/v1/partner/delete/${image._id}`);

      // Display success message
      toast.success(' успешно удалено');

      // Fetch the updated list of images
      fetchImages();
    } catch (error) {
      console.error('Ошибка удаления изображения.:', error);
      toast.error('Ошибка удаления изображения.');
    }
  };

  return (
    <>
      <ToastContainer />
      {navbar ? <Sidebar /> : null}
      <Navbar />
      <div className='main'>
        <div className="add-btn">
          <button onClick={() => setModal(true)}>Добавить</button>
        </div>
        <div className="image-container">
          {images && images.map((e) => (
            <div key={e.index} className="image-card">
              <img src={`https://api.interstudent.uz/uploads/${e.image}`} alt={e} />
              <button className='del-btn' onClick={() => handleDelete(e)}>удалить</button>
            </div>
          ))}
        </div>
      </div>
      {modal ?
        <div className="modal">
          <div className="modal-list">
            <div className="modal-title">
              <h4>Добавить слайдера</h4>
              <img src={ExitIcon} alt="" onClick={() => setModal(false)} />
            </div>
            <div className='main-form product-form'>
              <label>
                <input type="file" onChange={handleFileChange} />
              </label>
              <button onClick={()=>{
                handleUpload()
                setModal(false)
              }}>Добавлять</button>
            </div>
          </div>
        </div> : null
      }
    </>
  );
}

export default Partner;
