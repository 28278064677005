import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './News.scss';
import Sidebar from '../../Components/Sidebar/Sidebar';
import Navbar from '../../Components/Navbar/Navbar';
import { Context } from '../../Context/Context';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import ExitIcon from '../../images/exit.png';

function News() {
  const { navbar, setNavbar } = useContext(Context);
  const [selectedFile, setSelectedFile] = useState(null);
  const [images, setImages] = useState(null);
  const [title_uz, setTitleUz] = useState('');
  const [description_uz, setDescriptionUz] = useState('');
  const [title_en, setTitleEn] = useState('');
  const [description_en, setDescriptionEn] = useState('');
  const [title_ru, setTitleRu] = useState('');
  const [description_ru, setDescriptionRu] = useState('');
  const [editingItemId, setEditingItemId] = useState(null);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    fetchImages();
  }, []);
  let navigate = useNavigate()

  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Adding 1 to match the typical month numbering
  const day = currentDate.getDate();

  const fetchImages = async () => {
    try {
      const response = await axios.get('https://api.interstudent.uz/api/v1/news');
      setImages(response?.data?.data);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };
  const handleDelete = async (newsItem) => {
    try {
      await axios.delete(`https://api.interstudent.uz/api/v1/news/delete/${newsItem._id}`);
      toast.success('O\'chirildi');
      fetchImages();
    } catch (error) {
      console.error('Error deleting news:', error);
      toast.error('Failed to delete news');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('title_uz', title_uz);
      formData.append('description_uz', description_uz);
      formData.append('title_en', title_en);
      formData.append('description_en', description_en);
      formData.append('title_ru', title_ru);
      formData.append('description_ru', description_ru);
      formData.append('image', 'selectedFile.name'); // Append the selected file
      const imageForm = new FormData()
      imageForm.append('file', selectedFile)

      // Upload image to '/upload' endpoint
      const uploadResponse = await axios.post('https://api.interstudent.uz/upload', imageForm, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      // Extract the filename from the response
      const { filename } = uploadResponse.data;
      console.log(selectedFile);
      // Now, save the news details along with the filename to the database

      await axios.post('https://api.interstudent.uz/api/v1/news/create', {
        title_uz,
        description_uz,
        title_en,
        description_en,
        title_ru,
        description_ru,
        date: `${year}-${month}-${day}`,
        image: selectedFile.name// Save only the filename
      });

      toast.success("Qo'shildi");
      fetchImages();
      setModal(false);
    } catch (error) {
      console.error('Error adding news:', error);
      toast.error('Qayta urining');
    }
  };


  const handleEdit = (newsItem) => {
    setEditingItemId(newsItem._id);
    setTitleUz(newsItem.title_uz);
    setDescriptionUz(newsItem.description_uz);
    setTitleEn(newsItem.title_en);
    setDescriptionEn(newsItem.description_en);
    setTitleRu(newsItem.title_ru);
    setDescriptionRu(newsItem.description_ru);
    setSelectedFile(newsItem.image)
    setModal(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault()

    try {
      await axios.put(`https://api.interstudent.uz/api/v1/news/update/${editingItemId}`, {
        title_uz,
        description_uz,
        title_en,
        description_en,
        title_ru,
        description_ru,
        image: selectedFile.name
      });
      const imageForm = new FormData()
      imageForm.append('file', selectedFile)

      // Upload image to '/upload' endpoint
      const uploadResponse = await axios.post('https://api.interstudent.uz/upload', imageForm, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success('News updated successfully');
      fetchImages();
      setModal(false);
    } catch (error) {
      console.error('Error updating news:', error);
      toast.error('Failed to update news');
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  return (
    <>
      <ToastContainer />
      {navbar ? <Sidebar /> : null}
      <Navbar />
      <div className='main'>
        <div className="add-btn">
          <button onClick={() => setModal(true)}>Qo'shish</button>
        </div>
        <div className="news-container">
          {images &&
            images.map((newsItem) => (
              <div key={newsItem._id} className="news-card">
                {newsItem?.image && <img src={`https://api.interstudent.uz/uploads/${newsItem.image}`} alt="News" />}
                <div className="news-actions">
                  <button className='edit-btn' onClick={() => handleEdit(newsItem)}>Taxrirlash</button>
                  <button className='delete-btn' onClick={() => handleDelete(newsItem)}>O'chirish</button>
                </div>
              </div>   
            ))}
        </div>
      </div>
      {modal &&
        <div className="modal">
          <div className="modal-list">
            <div className="modal-title">
              <h4>{editingItemId ? 'Tahrirlash' : "Qo'shish"}</h4>
              <img src={ExitIcon} alt="Exit" onClick={() => {
                setModal(false)
                navigate(0)
              }} />
            </div>
            <form onSubmit={editingItemId ? handleUpdate : handleSubmit} className='main-form product-form'>
              <label>
                <input type="text" name="title_uz" value={title_uz} onChange={(e) => setTitleUz(e.target.value)} placeholder="Title (Uzbek)" required />
              </label>
              <label>
                <textarea type="text" name="description_uz" value={description_uz} onChange={(e) => setDescriptionUz(e.target.value)} placeholder="Description (Uzbek)" required />
              </label>
              <label>
                <input type="text" name="title_en" value={title_en} onChange={(e) => setTitleEn(e.target.value)} placeholder="Title (English)" required />
              </label>
              <label>
                <textarea type="text" name="description_en" value={description_en} onChange={(e) => setDescriptionEn(e.target.value)} placeholder="Description (English)" required />
              </label>
              <label>
                <input type="text" name="title_ru" value={title_ru} onChange={(e) => setTitleRu(e.target.value)} placeholder="Title (Russian)" required />
              </label>
              <label>
                <textarea type="text" name="description_ru" value={description_ru} onChange={(e) => setDescriptionRu(e.target.value)} placeholder="Description (Russian)" required />
              </label>
              <label>
                <input type="file" onChange={handleFileChange} />
              </label>
              <button type="submit">{editingItemId ? 'Yangilash' : 'Qo\'shish'}</button>
            </form>
          </div>
        </div>
      }
    </>
  );
}

export default News;
